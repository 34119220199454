<template>
  <section class="org-list">
    <a-table
        ref="historyListRef"
        :columns="columns"
        :data-source="historyList"
        rowKey="id"
        :scroll="{
        y: tableHeight,
        x: 1200,
        scrollToFirstRowOnChange: true,
      }"
        :pagination="pagination"
    >
    </a-table>
  </section>
</template>

<script>
import {useGlobalPropertyHook} from "@/hooks/common";
import {nextTick, onMounted, onUnmounted, reactive, ref} from "vue";
import {useTableHeight} from "@/utils/common";

export default {
  name: "CostCtrList",
  components: {},
  setup() {
    const {$api} = useGlobalPropertyHook();

    const historyListRef = ref();
    const columns = [
      {
        title: "Team",
        dataIndex: "team",
        key: "team",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Offering",
        dataIndex: "offering",
        key: "offering",
        ellipsis: true,
        width: 150,
      },
      {
        title: "GGID",
        dataIndex: "ggid",
        key: "ggid",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Name",
        dataIndex: "fullName",
        key: "fullName",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Update Date",
        dataIndex: "updateDate",
        key: "updateDate",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Update Record",
        dataIndex: "updateRecord",
        key: "updateRecord",
        ellipsis: true,
        width: 100,
      },
      {
        title: "Before",
        dataIndex: "changeBefore",
        key: "changeBefore",
        ellipsis: true,
        width: 150,
      },
      {
        title: "After",
        dataIndex: "changeAfter",
        key: "changeAfter",
        ellipsis: true,
        width: 150,
      },
    ];
    const pagination = reactive({
      current: 1,
      pageSize: 12,
      pageSizeOptions: ['12','20','30','40'],
      total: 0,
      showSizeChanger: true,
      showTotal: (total) => {
        return `共 ${total} 条`;
      },
      onChange: (page) => {
        pagination.current = page;
        doSearch(searchCondition);
      },
      onShowSizeChange: (_, size) => {
        pagination.pageSize = size;
        pagination.current = 1;
        doSearch(searchCondition);
      },
    });
    const historyList = ref();
    let searchCondition = {};

    const doSearch = (searchParams = searchCondition) => {
      const params = {
        pageIndex: pagination.current,
        pageSize: pagination.pageSize,
        ...searchParams,
        updateRecordList:searchParams?.updateRecordList?.length>0?[searchParams.updateRecordList] : []
      };
      $api.getHistoryList(params).then((res) => {
        pagination.total = res.data.total;
        historyList.value = res.data.resource;
      });
    };
    const searchClick = (searchParams) => {
      pagination.current = 1;
      searchCondition = searchParams;
      doSearch(searchCondition);
    };

    const tableHeight = ref(0);

    const getSize = () => {
      nextTick(() => {
        tableHeight.value = useTableHeight();
      });
    };

    window.addEventListener("resize", getSize);

    onMounted(() => {
      getSize();
      doSearch()
    });

    onUnmounted(() => {
      window.removeEventListener("resize", getSize);
    });

    return {
      historyListRef,
      columns,
      pagination,
      historyList,
      searchClick,
      tableHeight,
      doSearch,
    };
  }
}
</script>

<style scoped lang="less">
// 覆盖ant-design样式
::v-deep .ant-table-body {
  overflow: auto;
}

:deep(.ant-table-thead) {
  > tr > th {
    white-space: nowrap;
  }
}

:deep(.ant-table-tbody) {
  > tr > td {
    white-space: nowrap;
  }
}

.org-list {
  display: flex;
  width: 100%;
  max-height: calc(100% - 88px);

  .ant-table-wrapper {
    flex: 1;
    width: 0;
  }

  .org-info {
    width: 300px;
    //margin-left: 20px;
    background: #f4f4f4;
  }
}
</style>
