<template>
  <section class="top-filter">
    <div class="mb12">
      <FilterLayout @doReset="resetForm"
                    @doSearch="doSearch"
                    ref="filterLayoutRef">
        <template #customform>
          <a-form
              ref="formRef"
              class="form rms-form"
              layout="vertical"
              :model="formState"
          >
            <a-form-item label="搜索" name="condition" class="rms-form-item">
              <a-input
                  v-model:value="formState.condition"
                  placeholder="Name/GGID"
                  enter-button
                  allowClear
                  autocomplete="off"
              />
            </a-form-item>
            <a-form-item label="Team" name="orgThreeTwoList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgThreeTwoList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
                  @change="orgThreeTwoChange"
              >
                <a-select-option
                    v-for="(item, index) in orgThreeTwoOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Offering" name="orgFourOneOneList" class="rms-form-item">
              <SelectWithAll
                  v-model:value="formState.orgFourOneOneList"
                  placeholder="请选择"
                  mode="multiple"
                  showArrow
                  allowClear
                  :maxTagCount="1"
              >
                <a-select-option
                    v-for="(item, index) in orgFourOneOneOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </SelectWithAll>
            </a-form-item>
            <a-form-item label="Update Record" name="updateRecordList" class="rms-form-item">
              <a-select
                  show-search
                  v-model:value="formState.updateRecordList"
                  placeholder="请选择"
                  allowClear
              >
                <a-select-option
                    v-for="(item, index) in updateRecordOps"
                    :key="index"
                    :value="item"
                ><span :title="item">{{ item }}</span>
                </a-select-option>
              </a-select>
            </a-form-item>
          </a-form>
        </template>
        <template #operationBtns>
          <div class="buttons-wrap right">
            <Tooltip title="导出">
              <a-button class="" type="primary" ghost @click="exportClick" v-auth="['system:pageSearch:export']">
                <ExportOutlined />
              </a-button>
            </Tooltip>
          </div>
        </template>
      </FilterLayout>
    </div>
  </section>
</template>

<script>
import {onActivated, onMounted, reactive, ref} from "vue";
import { useGlobalPropertyHook } from "@/hooks/common";
import {cloneDeep} from "lodash";
import { ExportOutlined } from "@ant-design/icons-vue";

import Tooltip from '@/components/Tooltip'
export default {
  name: "TopFilter",
  components: { ExportOutlined,Tooltip },
  emits: ["search", "export-click", "refresh-table"],
  setup(props, ctx) {
    const { $api } = useGlobalPropertyHook();
    const formRef = ref();
    const formState = reactive({
      condition: "",
      orgThreeTwoList: [],
      orgFourOneOneList: [],
      updateRecordList: [],
    });
    const orgThreeTwoOps = ref([]);
    const orgFourOneOneOps = ref([]);
    const updateRecordOps = ref([]);
    const getOrgList = () => {
      $api.getOrgThree_FourList().then((res) => {
        const { orgThreeTwo, orgFourOneOne } = res.data;
        orgThreeTwoOps.value = orgThreeTwo;
        orgFourOneOneOps.value = orgFourOneOne;
        updateRecordOps.value = [
          'Cost Ctr','Termination Date','Company Code','Base Location','Grade','Reviewer Email','Cost Rate Code'
        ]
      });
    };
    const orgThreeTwoChange = () => {
      formState.orgFourOneOneList = [];
      if (formState.orgThreeTwoList.length) {
        $api
            .getOrgFour({ condition: formState.orgThreeTwoList.join() })
            .then((res) => {
              orgFourOneOneOps.value = res.data.orgFourOneOne;
            });
      } else {
        getOrgList();
      }
    };
    const resetForm = () => {
      formRef.value.resetFields();
      updateParams()
      ctx.emit("search", currentState);
    };
    let currentState = {}
    const updateParams =() => {
      currentState = cloneDeep(formState)
    }
    const doSearch = () => {
      updateParams()
      ctx.emit("search", currentState);
    };
    const exportClick = () => {
      ctx.emit("export-click", currentState);
    };
    onMounted(() => {
      getOrgList()
    });
    onActivated(() => {
      ctx.emit("refresh-table");
    });
    return {
      formState,
      formRef,
      doSearch,
      resetForm,
      exportClick,
      updateParams,
      orgThreeTwoOps,
      orgFourOneOneOps,
      updateRecordOps,
      getOrgList,
      orgThreeTwoChange,
    };
  }
}
</script>

<style scoped lang="less">
.top-filter {
  width: 100%;
}
.buttons-wrap{
  :deep(.anticon){
    font-size: 20px;
  }
}
</style>
