<template>
  <section class="app-container">
    <Filter
        @search="handleSearch"
        @export-click="exportClick"
        @refresh-table="handleResetTable"
    />
    <HistoryList ref="historyListRef"></HistoryList>
  </section>
</template>

<script>
import HistoryList from "@/views/home/BaseDataManagement/History/components/HistoryList";
import Filter from "@/views/home/BaseDataManagement/History/components/Filter";
import {useGlobalPropertyHook} from "@/hooks/common";
import {ref} from "vue";
import {downloadFile} from "@/utils/common";

export default {
  name: "pageSearch",
  components: {HistoryList,Filter},
  setup() {
    const { $api } = useGlobalPropertyHook();

    const historyListRef = ref();
    const handleSearch = (searchParams) => {
      historyListRef.value.searchClick(searchParams);
    };
    const handleResetTable = () => {
      historyListRef.value.doSearch();
    };

    const exportClick = (searchParams) => {
      const params = {
        ...searchParams,
        updateRecordList:searchParams?.updateRecordList?.length>0?[searchParams.updateRecordList] : []
      }
      $api.historyExportList(params).then((res) => {
        downloadFile(res.file, res.filename);
      });
    };
    return {
      historyListRef,
      handleSearch,
      handleResetTable,
      exportClick,
    };
  },
}
</script>
